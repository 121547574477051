import React from "react"
import { Link } from "gatsby"
import AnchorLink from "react-anchor-link-smooth-scroll"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AffPes from "../components/pes2021cta"

const contact = () => {
  return (
    <Layout>
      <SEO
        title="Playing Styles in PES 2021"
        description="Here is the complete list of Playing Styles available in PES 2021."
      />
      <div className="container">
        <h1>Playing Styles in PES 2021</h1>
        <ul className="breadcrumb" style={{ paddingTop: "1rem" }}>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>Playing Styles</li>
        </ul>
        <p>
          Playing Styles were introduced in 2014 and have been expanded in PES
          2019 with 4 more possibilities: Roaming Flank, Cross Specialist,
          Orchestrator, and Full-back Finisher.
        </p>
        <p>
          Each player can only have 1 Playing Style (some have none), but up to
          5{" "}
          <AnchorLink href="#complayingstyles" offset="70">
            COM Playing Styles
          </AnchorLink>
          . You can't change these player traits in MyClub.
        </p>
      </div>
      <AffPes />
      <nav id="toc">
        <div className="container">
          <span>Table of Contents</span>
          <ul>
            <li>
              <AnchorLink href="#playingstyleslist" offset="70">
                Playing Styles - The Complete List
              </AnchorLink>
            </li>
            <li>
              <AnchorLink href="#complayingstyles" offset="70">
                COM Playing Styles
              </AnchorLink>
            </li>
          </ul>
        </div>
      </nav>
      <div className="container">
        <h2 id="playingstyleslist">Playing Styles - The Complete List</h2>
        <p>
          Playing Styles are only activated if players are on their compatible
          position(s) and they affect the positioning and movement of the
          player.
        </p>
        <p>
          There are 21 Playing Styles. This is the official description of each
          one, as provided by Konami.
        </p>
        <h3 className="bullet">Goal Poacher (CF / SS)</h3>
        <p>
          A predatory striker who plays off the shoulders of the last defender.
        </p>
        <h3 className="bullet">Dummy Runner (CF / SS / AMF)</h3>
        <p>
          A player who attracts the defence to create space for other players to
          exploit.
        </p>
        <h3 className="bullet">Fox in the Box (CF)</h3>
        <p>
          A striker who lurks in the opposition 18 yard box just waiting for the
          ball.
        </p>
        <h3 className="bullet">Target Man (CF)</h3>
        <p>
          A player who becomes the focal point of attack by holding the ball up
          in an advanced position.
        </p>
        <h3 className="bullet">
          Creative Playmaker (SS / LWF / RWF / AMF / LMF / RMF)
        </h3>
        <p>
          A player who takes advantage of any opening in the defense to initiate
          attacks and assists in shots on the goal.
        </p>
        <h3 className="bullet">Prolific Winger (LWF / RWF)</h3>
        <p>
          A player who positions himself on the wing to receive passes,
          occasionally cutting into the center when the opportunity arises.
        </p>
        <h3 className="bullet">Roaming Flank (LWF / RWF / LMF / RMF)</h3>
        <p>A player who tends to cut inside from the wing to receive passes.</p>
        <h3 className="bullet">Cross Specialist (LWF / RWF / LMF / RMF)</h3>
        <p>
          A player who hugs the touchline, waiting for a chance to cross the
          ball in.
        </p>
        <h3 className="bullet">Classic No. 10 (SS / AMF / CMF)</h3>
        <p>
          An old-style static playmaker who makes use of deft touches and passes
          rather than pace or movement.
        </p>
        <h3 className="bullet">Hole Player (SS / AMF / LMF / RMF / CMF)</h3>
        <p>
          A player who looks to make runs into the opposition goal area when the
          team is on the attack.
        </p>
        <h3 className="bullet">Box-to-Box (AMF / LMF / RMF / CMF / DMF)</h3>
        <p>
          A player who tirelessly covers every blade of grass for the full 90
          minutes.
        </p>
        <h3 className="bullet">The Destroyer (CMF / DMF / CB)</h3>
        <p>
          A tenacious battler who keeps opposition attacks at bay through hard
          tackling and pressing.
        </p>
        <h3 className="bullet">Orchestrator (CMF / DMF)</h3>
        <p>
          A player who lurks in deeper positions, ready to initiate attacks.
        </p>
        <h3 className="bullet">Anchor Man (DMF)</h3>
        <p>A deep sitting defensive midfielder protecting the backline.</p>
        <h3 className="bullet">Build Up (CB)</h3>
        <p>
          A player who likes to drop back in order to receive the ball and
          trigger attacks from deep.
        </p>
        <h3 className="bullet">Offensive Full-back (LB / RB)</h3>
        <p>
          An attack-minded full back who will run upfield and join the attack
          when presented with a chance.
        </p>
        <h3 className="bullet">Full-back Finisher (LB / RB)</h3>
        <p>
          An attacking full-back who enjoys joining the attack in high central
          areas.
        </p>
        <h3 className="bullet">Defensive Full-back (LB / RB)</h3>
        <p>
          A solid full-back who prefers to stay back and stick to defensive
          duties.
        </p>
        <h3 className="bullet">Extra Frontman (CB)</h3>
        <p>
          A defender who likes to join in the attack and lay siege on the
          opposition goal at every given opportunity.
        </p>
        <h3 className="bullet">Offensive Goalkeeper (GK)</h3>
        <p>
          A keeper playing a sweeper type role who often comes out to cover the
          area behind the defence.
        </p>
        <h3 className="bullet">Defensive Goalkeeper (GK)</h3>
        <p>A solid keeper who prefers to stay around the goal line.</p>
        <h2 id="complayingstyles">COM Playing Styles</h2>
        <p>
          This will affect AI-controlled players only (useful for SIMs or Co-Op
          games).
        </p>
        <p>
          There are 7 COM Playing Styles and this is how Konami describes each
          one.
        </p>
        <h3 className="bullet">Trickster</h3>
        <p>
          The step-over expert who makes the most of exquisite skills to dribble
          past the opposition.
        </p>
        <h3 className="bullet">Mazing Run</h3>
        <p>
          A player who looks to penetrate deep into opposition territory by
          using deft turns and dribbles.
        </p>
        <h3 className="bullet">Speeding Bullet</h3>
        <p>A pacey player who likes to get forward.</p>
        <h3 className="bullet">Incisive Run</h3>
        <p>
          A dribbler whose expertise is to cut in from the wide areas looking
          for goal scoring opportunities.
        </p>
        <h3 className="bullet">Long Ball Expert</h3>
        <p>A player who frequently plays the long ball.</p>
        <h3 className="bullet">Early Cross</h3>
        <p>
          A player with great vision who won't miss the chance to hit an early
          cross.
        </p>
        <h3 className="bullet">Long Ranger</h3>
        <p>A player who frequently takes snapshots at goal from range.</p>
      </div>
    </Layout>
  )
}

export default contact
